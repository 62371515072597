import http from '@/http'

export async function putManualCheckIn (id: string, tagFilterList: number[]) {
  const response = await http.post('/admin/check-in/checked-in/' + id, { tags: tagFilterList }, {
    validateStatus: () => true
  })
  if (response.status === 200) {
    return {
      message: response.data.message,
      ok: true
    }
  }

  if (response.status === 422) {
    return {
      message: response.data.message,
      ok: false
    }
  }

  const exc: any = { CODID: '2ABAOUBF', response }
  exc['error'] = new Error(exc.CODID)
  throw exc
}
