<template>
  <div class="row gap-6">
    <div class="col col-md-auto">
      <b-button block :variant="variantValueIs('TODOS')" @click="() => changeButtonHandle('TODOS')">TODOS</b-button>
    </div>
    <div class="col col-md-auto">
      <b-button block :variant="variantValueIs('AGUARDANDO')" @click="() => changeButtonHandle('AGUARDANDO')">AGUARDANDO</b-button>
    </div>
    <div class="col-3 col-md-auto">
      <b-button block :variant="variantValueIs('IN')" @click="() => changeButtonHandle('IN')">IN</b-button>
    </div>
    <div class="w-100 d-md-none"></div>
    <div class="col col-md">
      <b-input-group>
        <template #prepend>
          <b-input-group-text>
            <i class='bx bx-search-alt'></i>
          </b-input-group-text>
        </template>
        <b-form-input
          v-model="search"
          :debounce="350"
          placeholder="Nome, e-mail..."
        />
      </b-input-group>
    </div>
    <div class="col-3 col-md-auto">
      <b-button block variant="light">FILTROS</b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index-filter',
  props: {
    // value: {
    //   type: String,
    //   validator (value) {
    //     return ['TODOS', 'AGUARDANDO', 'IN'].includes(value.toUpperCase())
    //   },
    //   default: 'TODOS'
    // }
    onFilter: {
      type: Function,
      default: () => null
    }
  },
  data: () => ({
    value: 'TODOS',
    search: ''
  }),
  methods: {
    changeButtonHandle (value) {
      this.value = value
    },
    variantValueIs (value) {
      return this.value === value ? 'dark' : 'light'
    },
    dispatchChange () {
      const filter = {}
      if (this.value === 'AGUARDANDO') {
        filter.onlyWaiting = true
      }
      if (this.value === 'IN') {
        filter.onlyCheckIn = true
      }
      if (this.search) {
        filter.search = this.search
      }
      this.onFilter(filter)
    }
  },
  watch: {
    value: 'dispatchChange',
    search: 'dispatchChange'
  }
}
</script>

<style scoped>
.gap-6 {
  gap: 6px;
}
</style>
