<template>
  <div class="footer">
    <b-button class="icon-box" :to="{name: routeScanName}">
      <img src="@/assets/images/icon/qr-code-scan.png" alt="">
    </b-button>
  </div>
</template>

<script>

import { CHECK_IN_PAGE_SCAN } from '@/modules/check-in/routes'

export default {
  name: 'index-footer',
  data: () => ({
    routeScanName: CHECK_IN_PAGE_SCAN
  })
}
</script>

<style scoped>
.footer {
  z-index: 2;
  background-color: #292f41;
  position: fixed;
}
.icon-box {
  color: #ffffff;
  font-size: 25px;
  background-color: #564AB1;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  position: absolute;
  display: grid;
  place-items: center;
  border: 0;
  top: -50%;
  left: 50%;
  transform: translate(-50%);
}
</style>
