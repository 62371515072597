import http from '@/http'

export type PaginationArg = {
  page: number,
  limit: number,
}

export type FilterArg = {
  search?: string | null,
  isStatusUnseen?: boolean,
  isStatusCheckIn?: boolean,
}

type ParamsType = {
  limit: number,
  page: number,
  search?: string,
  status?: string
}

export type Response = {
  list: {
    id: string
    isStatusCheckIn: boolean
    isStatusCheckOut: boolean
    name: string
    email: string
  }[]
  meta: {
    currentPage: number
    perPage: number
    totalPages: number
  }
}

export async function fetchAttendeeList (
  pagination: PaginationArg,
  filter: FilterArg = {}
): Promise<Response> {
  const params: ParamsType = {
    limit: pagination.limit,
    page: pagination.page
  }
  if (filter.search) {
    params['search'] = filter.search
  }
  if (filter.isStatusUnseen) {
    params['status'] = '0'
  }
  if (filter.isStatusCheckIn) {
    params['status'] = '1'
  }
  const response = await http.get('/admin/check-in/participants', { params })
  if (response.status !== 200) {
    const exc = { CODID: 'NC93S3NT', error: new Error('NC93S3NT'), response }
    throw exc
  }
  return {
    list: response.data.data.map((item: any) => ({
      id: item.id + '',
      isStatusCheckIn: item.status_check === 1,
      isStatusCheckOut: item.status_check === 2,
      name: item.name,
      email: item.email
    })),
    meta: {
      currentPage: response.data.meta.pagination.current_page,
      perPage: response.data.meta.pagination.per_page,
      totalPages: response.data.meta.pagination.total_pages
    }
  }
}
