<template>
  <div>
    <div class="content-box">
      <div class="container-fluid" v-infinite-scroll="fetchListPage" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
        <div class="row row-item" v-for="item in attendeeList" :key="'item-'+item.id" @click="() => navToItem(item)">
          <div class="col-auto">
            <div class="icone-status" :class="{'is-checked-in': item.isStatusCheckIn, 'is-checked-out': item.isStatusCheckOut}">
              <template v-if="item.isStatusCheckIn">
                <i class="far fa-check-circle text-white"></i>
              </template>
              <template v-else-if="item.isStatusCheckOut">
                <i class="bx bx-log-out-circle text-white"></i>
              </template>
              <template v-else>
                <i class="far fa-question-circle text-white"></i>
              </template>
            </div>
          </div>
          <div class="col texts-box text-truncate">
            <div class="text-1 text-truncate">{{ item.name }}</div>
            <div class="text-2 text-truncate">{{ item.email }}</div>
          </div>
          <div class="col-auto">
            <div class="fix-w">
              <b-button
                block
                :variant="!item.isStatusCheckIn ? 'outline-success' : 'outline-warning'"
                @click.prevent.stop="() => actionClickHandle(item)"
                :disabled="item.onAction"
              >
                <template v-if="!item.isStatusCheckIn">
                  <i class="fas fa-check-circle"></i>
                  Check In
                </template>
                <template v-else>
                  <i class='bx bx-log-out-circle'></i>
                  Check Out
                </template>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import infiniteScroll from 'vue-infinite-scroll'
import { fetchAttendeeList } from '@/modules/check-in/service/fetchAttendeeList.ts'
import { putManualCheckIn } from '@/modules/check-in/service/putManualCheckIn'
import { putManualCheckOut } from '@/modules/check-in/service/putManualCheckOut'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { CHECK_IN_MODULE } from '@/modules/check-in'

export default {
  name: 'index-list',
  directives: { infiniteScroll },
  data: () => ({
    pagination: {
      currentPage: 0,
      perPage: 30,
      totalPages: 0
    },
    attendeeList: [],
    busy: false,
    currentFilter: {
      search: null,
      isStatusUnseen: null,
      isStatusCheckIn: null
    }
  }),
  computed: {
    listItemsDetail () {
      return this.$store.state[CHECK_IN_MODULE].list
    }
  },
  watch: {
    listItemsDetail: {
      handler (listItemsDetail) {
        this.attendeeList
          .filter(({ id }) => !!listItemsDetail[id])
          .forEach(item => this.updateItemStatus(item, listItemsDetail[item.id]))
      },
      deep: true
    }
  },
  methods: {
    async actionClickHandle (item) {
      item.onAction = true
      let action = putManualCheckIn
      if (item.isStatusCheckIn) {
        action = putManualCheckOut
      }
      try {
        const { message, ok } = await action(item.id)
        if (!ok) {
          errorMessage(message ?? 'Ocorreu algum erro ao realizar essa ação.')
        } else {
          successMessage(message ?? 'Ação realizada com sucesso.')
          item.isStatusCheckIn = !item.isStatusCheckIn
          item.isStatusCheckOut = !item.isStatusCheckIn
        }
      } catch ({ serverMessage }) {
        errorMessage(serverMessage ?? 'Ocorreu algum erro ao realizar essa ação.')
      } finally {
        item.onAction = false
      }
    },
    navToItem ({ id }) {
      this.$store.commit(CHECK_IN_MODULE + '/setShowDetailId', id)
      // this.$router.push({ name: CHECK_IN_PAGE_INFO, params: { id } })
    },
    filter (search, isStatusUnseen, isStatusCheckIn) {
      this.currentFilter.search = search
      this.currentFilter.isStatusUnseen = isStatusUnseen
      this.currentFilter.isStatusCheckIn = isStatusCheckIn
      this.resetList()
      this.fetchListPage()
    },
    async fetchListPage () {
      this.busy = true
      if (this.pagination.totalPages === this.pagination.currentPage) {
        return
      }
      const response = await fetchAttendeeList(
        { page: this.pagination.currentPage + 1, limit: this.pagination.perPage },
        { search: this.currentFilter.search, isStatusCheckIn: this.currentFilter.isStatusCheckIn, isStatusUnseen: this.currentFilter.isStatusUnseen }
      )
      this.busy = false
      this.pagination.currentPage = response.meta.currentPage
      this.pagination.totalPages = response.meta.totalPages
      this.attendeeList.push(...response.list.map(item => ({ ...item, onAction: false })))
    },
    updateItemStatus (item, itemDetail) {
      item.isStatusCheckIn = itemDetail.isStatusCheckIn
      item.isStatusCheckOut = itemDetail.isStatusCheckOut
    },
    resetList () {
      this.attendeeList = []
      this.pagination.currentPage = 0
      this.pagination.totalPages = null
    }
  },
  created () {
    this.pagination.currentPage = 0
    this.pagination.totalPages = null
    this.fetchListPage()
  }
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "src/design/variables";

.fix-w {
  --width: 94px;
  --font-size: 12px;
  --icon-size: 18px;
  --btn-justify: space-evenly;
  --btn-padding: 0.45rem 0.08rem;
  @media (min-width: map-get($grid-breakpoints, "md")) {
    --width: 160px;
    --font-size: 18px;
    --icon-size: 26px;
    --btn-justify: space-around;
    --btn-padding: 0.57rem 0.75rem;
  }

  width: var(--width);

  button {
    font-size: var(--font-size);
    display: flex;
    align-items: center;
    justify-content: var(--btn-justify);
    padding: var(--btn-padding);

    i {
      font-size: var(--icon-size);
    }
  }
}

.row-item {
  --padding: 10px 5px;
  @media (min-width: map-get($grid-breakpoints, "md")) {
    --padding: 9px 23px;
  }
  background-color: #ffffff;
  border-radius: 5px;
  margin-bottom: 16px;
  padding: var(--padding);
  border: 1px solid rgba(233, 233, 233, 0.59);
  align-items: center;
  cursor: pointer;
}

.icone-status {
  --width: 35px;
  --height: 35px;
  --font-size: 23px;
  @media (min-width: map-get($grid-breakpoints, "md")) {
    --width: 78px;
    --height: 67px;
    --font-size: 40px;
  }
  width: var(--width);
  height: var(--height);
  border-radius: 7px;
  display: grid;
  place-items: center;
  background-color: #6B7280;
  font-size: var(--font-size);

  &.is-checked-in {
    background-color: #09A96F;
  }

  &.is-checked-out {
    background-color: #F1B44C;
  }
}

.texts-box {
  .text-1 {
    font-size: 16px;
    color: #05162B;
  }

  .text-2 {
    font-size: 14px;
    color: #05162B87;
  }
}
</style>
