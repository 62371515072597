<template>
  <Layout height-full>
<!--    <div class="py-3">
      <div class="bg-success d-block d-md-none p-3 text-uppercase font-weight-bold">
        mobile on
      </div>
      <div class="bg-info text-white d-none d-md-block p-3 text-uppercase font-weight-bold">
        mobile off
      </div>
    </div>-->
    <div class="box">
      <index-filter class="box-filter" :on-filter="filter => applyFilter(filter)"/>
      <index-list ref="list" class="box-list"/>
    </div>
    <index-footer/>
  </Layout>
</template>

<script>
import Layout from '@/components/layout/vertical'
import IndexFilter from '@/modules/check-in/pages/index/index-filter'
import IndexFooter from '@/modules/check-in/pages/index/index-footer'
import IndexList from '@/modules/check-in/pages/index/index-list'

export default {
  name: 'index',
  components: {
    IndexList,
    IndexFooter,
    IndexFilter,
    Layout
  },
  page: {
    title: 'Check-In'
  },
  methods: {
    applyFilter (filter) {
      this.$refs.list.filter(filter.search ?? null, filter.onlyWaiting ?? null, filter.onlyCheckIn ?? null)
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  min-height: 100%;
  // display: grid;
  grid-auto-rows: min-content auto;
  .box-filter {
    margin-bottom: 24px;
  }
}
</style>
